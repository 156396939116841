import React from "react";

function PageNotFound() {
  return (
    <div
    className="not-found"
    >
    <h1 className="not-found-404">404</h1>
      <h1>Page not found</h1>
    </div>
  );
}

export default PageNotFound;
